//TODO Store all css variables

$assets-path: '/assets/images/';

$font-size: 16px;
$font-size-samall: 13px;
$font-size-large: 35.5px;

$font-weight-regular: 400;
$font-weight-bold: 600;

$black: #000;
$light_black: #222222;
$grey:  #aaaaaa;
//$grey: #6B6F73;
$white: #fff;
$red: #D13532;

$muli-extralight: 'Muli-ExtraLight', sans-serif;
$muli-light: 'Muli-Light', sans-serif;
$muli-regular: 'Muli-Regular', sans-serif;
$muli-semibold: 'Muli-SemiBold', sans-serif;
$muli-bold: 'Muli-Bold', sans-serif;


$poppins-regular: 'Poppins-Regular', sans-serif;
$poppins-medium: 'Poppins-Medium', sans-serif;
$poppins-semibold: 'Poppins-SemiBold', sans-serif;

$bebaskai: 'BebasKai', sans-serif;