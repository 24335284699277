@import './styles/mixins';
@import './styles/variables';
@import './styles/fonts';

body {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
  .main {
    font-size: $font-size;
    position: relative;
    height: 100%;
    overflow: hidden;

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #f00;
      border-radius: 3px;
      outline: 1px solid #ced4d9;
      height: 20px;
    }
  }
}

@media screen and (max-width: 600px) {
  body {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }
    .main {
      font-size: $font-size;
      position: relative;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #f00;
        border-radius: 3px;
        outline: 1px solid #ced4d9;
        height: 20px;
      }
    }
  }
}

@media screen and (orientation: landscape) {
  body {
    overflow: hidden;
    .main {
      overflow: hidden;
    }
  }
}


.container {
  padding-left: 12.5%;
  padding-right: 12.5%;
}

.___ {
  width: 60px;
  height: 1px;
  background-color: $red;
  //margin: auto;
}

.bttn {
  cursor: pointer;
  border-radius: 35px;
  font-family: $muli-regular;
  position: relative;
  background-color: $white;
  border: solid 1px #dddddd;
  text-decoration: none;
  color: #000000;
  //padding-top: 23px;
  height: 60px;
  width: 240px;
  //display: block;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .right_arrow {
      position: absolute;
      top: 39%;
      right: 10%;
  }
}

.section_header {
  text-align: center;
  font-weight: 500;
  padding: 48px 0;
  font-family: $poppins-regular;
  letter-spacing: 0.45px;
}

.section_title {
  font-size: 48.5px;
  font-family: $muli-extralight;
  font-weight: 200;
}

.section_text {
  font-size: 16px;
  font-family: $muli-regular;
  font-weight: 300;
  line-height: 1.88;
  letter-spacing: 0.4px;
  color: #444444;
}


.info_section {
  position: relative;
  height: 1000px;
  .container {
      //padding-right: 0;
      display: flex;
      align-items: center;
      height: 100%;
  }
  .text_block {
      z-index: 2;
      .label {
        font-family: $poppins-semibold;
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 2.85px;
        margin-bottom: 48px;
      }

      .section_title {
          font-size: 40.5px;
          width: 336px;
      }
      .section_text {
          width: 545px;
      }
      .___ {
          margin: 0;
          margin-bottom: 49px;
          margin-top: 41px;
      }
  }
  .decorative_img {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0;
  }
}
