@import "src/styles/variables";
$path: '/assets/images/raynorgroup';

.wrapper {
  width: 100%;
  height: 100%;

  .bg {
    position: absolute;
    text-align: right;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .text_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;

    .title {
      font-family: $muli-extralight;
      font-size: 40px;
      font-weight: 400;
      line-height: 50px;
      letter-spacing: 0.74px;
      color: #000000;

      width: 90%;
      position: relative;
      margin-bottom: 50px;

      &:before {
        display: block;
        content: "";
        height: 1px;
        width: 80px;
        position: absolute;
        bottom: -25px;
        left: 0;
        background-color: $red;
      }
    }

    .description_container {
      width: 80%;
      height: auto;
      max-height: calc(100% - 550px);

      margin-bottom: 30px;

      overflow-y: auto;
      overflow-x: hidden;

      .description {
        width: 95%;
        color: #444444;
        font-family: $muli-regular;
        font-size: 16px;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: 0.4px;
      }


      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #ff5956;
        border-radius: 3px;
        outline: 1px solid #ced4d9;
        height: 20px;
      }
    }
  }
}

@media screen and (min-width: 960px) and (max-width: 1279px){
  .wrapper {
    .text_content {
      height: 100%;
      padding-left: 10%;

      .title {
        font-family: $muli-extralight;
        font-size: 30px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0.75px;

        width: 100%;
        position: relative;
        margin-bottom: 50px;

        &:before {
          display: block;
          content: "";
          height: 1px;
          width: 80px;
          position: absolute;
          bottom: -25px;
          left: 0;
          background-color: $red;
        }
      }

      .description_container {
        width: 70%;
        .description {
          width: 95%;
          font-size: 14px;
          line-height: 28px;
          letter-spacing: 0.35px;
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 959px){
  .wrapper {
    .text_content {
      padding-left: 10%;
      margin-top: -5%;

      .title {
        font-family: $muli-extralight;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.6px;

        width: 100%;
        position: relative;
        margin-bottom: 30px;

        &:before {
          display: block;
          content: "";
          height: 1px;
          width: 80px;
          position: absolute;
          bottom: -15px;
          left: 0;
          background-color: $red;
        }
      }

      .description_container {
        width: 90%;
        .description {
          width: 95%;
          font-size: 14px;
          line-height: 28px;
          letter-spacing: 0.35px;
        }
      }
    }
  }
}