// ====================================
// Reset styles
// ====================================
@mixin reset($type: null) {
  @if $type == spacing { margin: 0; padding: 0; }
  @if $type == list { margin: 0; padding: 0; list-style: none;
    & li { margin: 0; padding: 0; }
  }
  @if $type == everything { float: none; position: static; margin: 0; padding: 0; background-color: transparent; border: none; list-style: none; }
}

// ====================================
// String Replace
// ====================================
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// ====================================
// Font Face
// ====================================
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff ttf svg) {
  $src: null;

  $extmods: (
          eot: "?",
          svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
          otf: "opentype",
          ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

