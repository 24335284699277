
.vertical_dots
{
    padding: 0;
    left: 23px;
    list-style: none;
    display: block;
    position: absolute;
    top: 40%;
    text-align: right;

}
.vertical_dots li
{
    position: relative;
    width: 20px;
    height: 30px;
    cursor: pointer;
}
.vertical_dots li button
{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    padding-left: 3px;

}
.vertical_dots li button:hover,
.vertical_dots li button:focus
{
    outline: none;
    color: #D13532 !important;
}
.vertical_dots li button:hover:before,
.vertical_dots li button:focus:before
{
    opacity: 1;
    color: #D13532 !important;
    font-size: 10px;
}

.vertical_dots li button:before
{
    font-family: 'slick', serif;
    font-size: 25px;
    line-height: 30px;

    position: absolute;
    top: 0;
    left: 0;

    width: 10px;
    height: 10px;

    content: '•';
    text-align: center;

    color: #cccccc;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.vertical_dots .slick-active button::before
{
    opacity: 1;
    color: #D13532 !important;
    font-size: 18px !important;
    padding: 0;
}
